import * as React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import Layout from '../components/layout'
import OffichoursBookingForm from '../components/officehoursBookingForm'
import * as Styles from '../styles/layout.module.scss'

const Officehours
  = ({ pageContext }) => {

    const data = useStaticQuery(graphql`
    query MyOhENQuery {
        allAirtableOfficehours(sort: {fields: data___Date}) {
          edges {
            node {
              data {
                Date(formatString: "D.M.YYYY")
                compareDate: Date(formatString: "YYYYMMDD")
                Time
              }
              recordId
            }
          }
        }
      }`)

      var translationContext = { locale:      'en',
                                 defaultMenu: false };
    const currentDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('')
    return (
      <Layout title="SAP ABAP and HANA Consulting Hour"
        pageContext={pageContext}
        translationContext={translationContext}
      >

        <div class={Styles.singleTopicLayout}>
          <main>
            <div class="entry-content clear" itemprop="text">
              <h1>SAP ABAP and HANA Consulting Hour</h1>
              <p>
              In this consultation hour, we cover all topics for which we also offer training. The focus is accordingly on programming and HANA or SAP BW modelling. From A for ABAP to S for SQLScript, we look forward to your questions and topics.
                <strong> Participation is free of charge</strong>
                . </p>
              <h3>Planned consultation hours</h3>
              <p>The event will only take place if a participant registers in advance. Therefore, please use the registration form. Then we can also send you the access data for the Zoom Session by e-mail before the event.</p>
              <ul>
                {
                  data.allAirtableOfficehours.edges.filter(edge => edge.node.data.compareDate >= currentDate)
                    .map((edge) => (

                      <li>
                        {edge.node.data.Date + ' um ' + edge.node.data.Time + ' Uhr '}
                      </li>

                    ))
                }
              </ul>
              <OffichoursBookingForm data={data} />
            </div>
          </main>
        </div>
      </Layout>

    )
  }

export default Officehours